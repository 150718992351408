import React, { useState } from "react";
import logo from "../icons/logo.svg";
import { Link } from "react-router-dom";
import { HiMenu, HiX, HiChevronDown } from "react-icons/hi";
import { FaCoins } from "react-icons/fa";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
      <div className="w-full px-5 py-4 bg-black sticky top-0 z-50">
        <div className="flex items-center justify-between w-full max-w-screen-xl mx-auto relative">
          <Link to="/" className="flex items-center justify-center z-20 w-32">
            <img src={logo} alt="logo" className="h-7" />
          </Link>
          <nav className="hidden md:flex gap-6 justify-center">
            {/* <Link
              to="/tools"
              className="px-4 py-2 text-sm text-neutral-300 transition-all duration-200 hover:text-white relative after:content-[''] after:absolute after:bottom-[-4px] after:left-0 after:w-full after:h-[2px] after:bg-primary-500 after:transition-all after:duration-300 after:opacity-0 after:translate-y-1 hover:after:translate-y-0 hover:after:opacity-100 after:rounded-full"
            >
              Tools
            </Link> */}
            <Link
              to="/"
              className="px-4 py-2 text-sm text-neutral-300 transition-all duration-200 hover:text-white relative after:content-[''] after:absolute after:bottom-[-4px] after:left-0 after:w-full after:h-[2px] after:bg-primary-500 after:transition-all after:duration-300 after:opacity-0 after:translate-y-1 hover:after:translate-y-0 hover:after:opacity-100 after:rounded-full"
            >
              Home
            </Link>
            <Link
              to="/marketplace"
              className="px-4 py-2 text-sm text-neutral-300 transition-all duration-200 hover:text-white relative after:content-[''] after:absolute after:bottom-[-4px] after:left-0 after:w-full after:h-[2px] after:bg-primary-500 after:transition-all after:duration-300 after:opacity-0 after:translate-y-1 hover:after:translate-y-0 hover:after:opacity-100 after:rounded-full"
            >
              Marketplace
            </Link>
            {/* <Link
              to="/pricing"
              className="px-4 py-2 text-sm text-neutral-300 transition-all duration-200 hover:text-white relative after:content-[''] after:absolute after:bottom-[-4px] after:left-0 after:w-full after:h-[2px] after:bg-primary-500 after:transition-all after:duration-300 after:opacity-0 after:translate-y-1 hover:after:translate-y-0 hover:after:opacity-100 after:rounded-full"
            >
              Pricing
            </Link> */}
            <Link
              to="/contact"
              className="px-4 py-2 text-sm text-neutral-300 transition-all duration-200 hover:text-white relative after:content-[''] after:absolute after:bottom-[-4px] after:left-0 after:w-full after:h-[2px] after:bg-primary-500 after:transition-all after:duration-300 after:opacity-0 after:translate-y-1 hover:after:translate-y-0 hover:after:opacity-100 after:rounded-full"
            >
              Contact
            </Link>
          </nav>
          {/* <div className="hidden md:flex items-center gap-4 z-20">
            {!isLoggedIn ? (
              <button
                onClick={() => setIsLoggedIn(true)}
                className="px-6 py-2 text-sm text-white bg-primary-600 rounded-full hover:bg-primary-700 transition-colors duration-200"
              >
                Login
              </button>
            ) : (
              <div className="relative group">
                <button className="flex items-center gap-3 px-4 py-2 text-sm text-white border border-primary-500/20 rounded-full bg-primary-600/10 hover:text-neutral-200 transition-colors duration-200">
                  <FaCoins className="text-yellow-500" />
                  <span className="font-medium">1,140 points</span>
                  <HiChevronDown className="transition-transform duration-200 group-hover:rotate-180" />
                </button>
                <div className="absolute right-0 top-full mt-2 w-48 bg-black border border-neutral-800 rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                  <div className="py-2">
                    <Link to="/profile" className="block px-4 py-2 text-sm text-neutral-300 hover:text-white hover:bg-white/5">
                      Profile
                    </Link>
                    <button
                      onClick={() => setIsLoggedIn(false)}
                      className="w-full text-left px-4 py-2 text-sm text-neutral-300 hover:text-white hover:bg-white/5"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div> */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden z-20 text-white"
          >
            {isOpen ? <HiX size={24} /> : <HiMenu size={24} />}
          </button>
        </div>
      </div>
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-secondary-950 transform transition-transform duration-300 ease-in-out z-40 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col pt-20 px-4 gap-4">
          <Link
            to="/"
            className="px-4 py-2 text-sm text-neutral-300 transition-all duration-200 rounded-full hover:text-white hover:bg-white/10"
            onClick={() => setIsOpen(false)}
          >
            Home
          </Link>
          {/* <Link
            to="/tools"
            className="px-4 py-2 text-sm text-neutral-300 transition-all duration-200 rounded-full hover:text-white hover:bg-white/10"
            onClick={() => setIsOpen(false)}
          >
            Tools
          </Link> */}
          <Link
            to="/marketplace"
            className="px-4 py-2 text-sm text-neutral-300 transition-all duration-200 rounded-full hover:text-white hover:bg-white/10"
            onClick={() => setIsOpen(false)}
          >
            Marketplace
          </Link>
          <Link
            to="/contact"
            className="px-4 py-2 text-sm text-neutral-300 transition-all duration-200 rounded-full hover:text-white hover:bg-white/10"
            onClick={() => setIsOpen(false)}
          >
            Contact
          </Link>
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Navbar;
