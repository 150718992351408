import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Marketplace from './pages/Marketplace';
// import Tools from './pages/Tools';
import Contact from './pages/Contact';
import Custom404 from './pages/404';
import ProductDetails from './pages/ProductDetails';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import Layout from './components/Layout';
import ScrollToTop from './components/ScrollToTop';
// import PricingPage from './pages/Pricing';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route
            path="/marketplace"
            element={<Layout><Marketplace /></Layout>}
          />
          {/* <Route
            path="/tools"
            element={<Layout><Tools /></Layout>}
          /> */}
          <Route
            path="/product/:link"
            element={<Layout><ProductDetails /></Layout>}
          />
          <Route path="/contact" element={<Layout><Contact /></Layout>} />
          {/* <Route path="/pricing" element={<Layout><PricingPage /></Layout>} /> */}
          <Route key="404" path="*" element={<Custom404 />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
