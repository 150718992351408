import ContactUs from "../components/ContactUs";
import MainShell from "../components/MainShell";

const Contact = () => {
  return (
    <div className="relative py-12 md:py-20 bg-black min-h-screen overflow-hidden">
      <div className="absolute inset-0 pointer-events-none overflow-visible">
        <div className="absolute -top-44 -left-44 w-[600px] h-[600px] bg-primary-600/20 rounded-full blur-[100px]" />
        <div className="absolute -bottom-44 -right-44 w-[600px] h-[600px] bg-primary-600/15 rounded-full blur-[100px]" />
      </div>

      <MainShell>
        <div className="relative grid grid-cols-1 gap-12 md:grid-cols-2">
          <div className="flex flex-col max-w-2xl gap-1">
            <p className="text-base uppercase font-semibold tracking-widest bg-gradient-to-r from-primary-500 to-primary-300 text-transparent bg-clip-text">
              Contact Us
            </p>
            <div className="flex flex-col gap-2">
              <h2 className="max-w-md text-4xl font-bold text-white md:text-5xl leading-tight md:leading-tight font-heading">
                Have a question?
                <br />
                Ask us!
              </h2>
              <p className="text-lg leading-normal md:leading-normal md:text-xl text-neutral-400">
                To ensure your satisfaction, please do not hesitate to reach out
                to us with any questions or concerns through the provided
                contact form or directly at:{" "}
                <a
                  href="mailto:hello@modular-web.com"
                  className="text-primary-500 hover:text-primary-400 transition-colors"
                >
                  hello@modular-web.com
                </a>
              </p>
            </div>
          </div>
          <ContactUs />
        </div>
      </MainShell>
    </div>
  );
};

export default Contact;
