import React from "react";

const SectionHeader = ({ heading, subheading }) => {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-4xl font-bold md:text-5xl text-white">{heading}</h2>
      <p className="text-lg leading-tight md:text-xl text-neutral-400">{subheading}</p>
    </div>
  );
};

export default SectionHeader;
