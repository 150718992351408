import React from 'react';
import phone2 from "../images/phone_02.webp";
import phone3 from "../images/phone_03.webp";
import phone4 from "../images/phone_04.webp";
import Button from "../components/Button";

const ExperienceItems = () => {
  const experienceItems = [
    {
      title: "Workouts That Fit",
      description:
        "Discover curated workout plans or create your own. Beautifully designed, infinitely customizable.",
      image: phone4,
    },
    {
      title: "Magical Tracking",
      description:
        "Speak, snap, or type to track your nutrition with delightful ease. Effortlessly precise, beautifully simple.",
      image: phone2,
    },
    {
      title: "Beautiful Insights",
      description:
        "Watch your journey unfold through elegantly crafted visualizations that tell the story of your progress.",
      image: phone3,
    }
  ];

  return (
    <div className="relative pt-14 pb-32 w-full px-6">
      {/* Decorative Blobs */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div
          className="absolute w-[600px] h-[600px] rounded-full opacity-30 blur-[100px]"
          style={{
            background: "linear-gradient(180deg, #FF3366 0%, #FF6B3D 100%)",
            top: "10%",
            left: "-20%",
          }}
        />
        <div
          className="absolute w-[600px] h-[600px] rounded-full opacity-20 blur-[100px]"
          style={{
            background: "linear-gradient(180deg, #3366FF 0%, #33CCFF 100%)",
            top: "40%",
            right: "-10%",
          }}
        />
        <div
          className="absolute w-[600px] h-[600px] rounded-full opacity-25 blur-[100px]"
          style={{
            background: "linear-gradient(180deg, #9933FF 0%, #FF33CC 100%)",
            bottom: "5%",
            left: "-15%",
          }}
        />
      </div>

      <div className="max-w-screen-xl mx-auto">
       

        <div className="flex flex-col gap-32 relative z-10">
          {experienceItems.map((item, index) => (
            <div
              key={index}
              className="grid items-center grid-cols-1 gap-16 text-left md:gap-24 sm:grid-cols-2"
            >
              <div
                className={`flex flex-col justify-center text-center md:justify-start md:text-left items-center md:items-start ${
                  index % 2 === 1 ? "sm:order-2" : ""
                }`}
              >
                <div className="flex flex-col gap-6">
                  <h3 className="text-5xl md:text-6xl md:leading-tight text-neutral-50 leading-tight font-extrabold font-condensed">
                    {item.title}
                  </h3>
                  <p className="max-w-md text-lg leading-normal md:leading-normal md:text-xl text-neutral-400">
                    {item.description}
                  </p>
                </div>
                <a 
                  href="https://stridez.io" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="mt-8"
                >
                  <Button variant="primary" className="bg-primary-500 hover:bg-primary-600 text-white">
                    Try Stridez Now
                  </Button>
                </a>
              </div>
              <div>
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full max-w-md h-[600px] px-4 mx-auto object-contain"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExperienceItems;