import React from "react";

function Button({
  variant,
  className,
  isLoading,
  disabled,
  children,
  isSmall,
  type = "button",
  ...props
}) {
  const buttonClass =
    variant === "secondary"
      ? "text-neutral-700 bg-white border-neutral-300 hover:bg-neutral-50"
      : "text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700";

  const disabledClass = disabled ? "opacity-50 cursor-not-allowed" : "";

  return (
    <button
      className={`flex items-center justify-center gap-2 px-5 ${
        isSmall ? "py-2" : "py-3"
      } text-base font-semibold rounded-md border whitespace-nowrap transition-all duration-300 cursor-pointer ${buttonClass} ${className} ${disabledClass}`}
      disabled={disabled}
      type={type}
      {...props}
    >
      {isLoading && <span className="button-loader" />}
      {children}
    </button>
  );
}

export default Button;
