import React from "react";
import { useNavigate } from "react-router-dom";
import Hero from "../components/Hero";
import MainShell from "../components/MainShell";
import SectionHeader from "../components/SectionHeader";
import ExperienceSection from "../components/ExperienceSection";
import StridezShowcase from "../components/StridezShowcase";
import { useProducts } from "../hooks/useProducts";
import ProductCard from "../components/ProductCard";
import Button from "../components/Button";
import { HiVideoCamera, HiPhotograph, HiMicrophone, HiArrowRight } from "react-icons/hi";

const Home = () => {
  const navigate = useNavigate();
  const { data: products = [] } = useProducts();
  const featuredProducts = products.slice(0, 3);

  // Featured tools data
  const featuredTools = [
    {
      name: "Smart Video Converter",
      description: "Convert between formats with AI-optimized settings",
      icon: HiVideoCamera,
      category: "video",
      gradient: "from-primary-500/20 to-primary-400/20",
      iconColor: "text-primary-400"
    },
    {
      name: "Text to Image Generator",
      description: "Create images from text descriptions",
      icon: HiPhotograph,
      category: "image",
      gradient: "from-emerald-500/20 to-emerald-400/20",
      iconColor: "text-emerald-400"
    },
    {
      name: "Voice Over Generator",
      description: "Convert text to natural-sounding speech",
      icon: HiMicrophone,
      category: "audio",
      gradient: "from-violet-500/20 to-violet-400/20",
      iconColor: "text-violet-400"
    },
  ];

  return (
    <div className="bg-black overflow-hidden">
      <Hero />
      <MainShell>
        <div className="flex flex-col gap-16 py-16 md:py-24" id="products">
          {/* Products Section */}
          <section>
            <SectionHeader
              heading="Discover our latest creations"
              subheading="Innovative solutions that define the future"
            />
            <div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-3">
              {featuredProducts.map((product, index) => (
                <ProductCard key={index} {...product} />
              ))}
            </div>
          </section>

          {/* New Tools Section */}
          {/* <section className="relative mt-16">
            <div className="absolute inset-0 pointer-events-none">
              <div className="absolute -top-44 -left-44 size-[600px] bg-primary-600/30 rounded-full blur-[200px]" />
              <div className="absolute -bottom-44 -right-44 size-[600px] bg-primary-600/20 rounded-full blur-[200px]" />
            </div>

            <SectionHeader
              heading="Modular Power Tools"
              subheading="Transform your content with our advanced tools"
              className="text-white"
              subheadingClassName="text-neutral-400"
            />

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
              {featuredTools.map((tool, index) => (
                <div
                  key={index}
                  className={`group relative bg-gradient-to-br ${tool.gradient} backdrop-blur-sm border border-white/10 rounded-2xl p-8
                    hover:border-white/20 transition-all duration-300 hover:shadow-2xl hover:shadow-black/20`}
                >
                  <div className="flex items-center justify-between mb-6">
                    <div className={`size-12 flex items-center justify-center rounded-xl bg-white/10 ${tool.iconColor}`}>
                      <tool.icon className="w-6 h-6" />
                    </div>
                    <HiArrowRight className={`w-5 h-5 ${tool.iconColor} group-hover:translate-x-1 transition-transform`} />
                  </div>

                  <h3 className={`text-2xl font-heading text-white group-hover:${tool.iconColor} transition-colors`}>
                    {tool.name}
                  </h3>
                  
                  <p className="mt-3 text-neutral-400 leading-relaxed">
                    {tool.description}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-12 text-center">
              <Button
                variant="primary"
                onClick={() => navigate('/tools')}
                className="bg-primary-500 hover:bg-primary-600 text-white px-8 py-3 inline-flex items-center gap-2"
              >
                Explore All Tools
                <HiArrowRight className="w-5 h-5" />
              </Button>
            </div>
          </section> */}

          <section>
            <div className="text-center mt-24">
              <h2 className="text-5xl md:text-6xl text-white font-bold font-condensed mb-6">
                Elevate Your Fitness Journey
              </h2>
              <p className="text-xl text-neutral-400 leading-normal max-w-2xl mx-auto">
                Stridez combines intelligent technology with personalized
                training to deliver an unmatched fitness experience. Your smart
                companion for achieving your goals.
              </p>
            </div>
          </section>
          {/* Stridez App Showcase */}
          <section>
            <StridezShowcase />
          </section>
        </div>
      </MainShell>
      <ExperienceSection />
    </div>
  );
};

export default Home;
