import React, { useState } from 'react';
import { HiChevronUp } from 'react-icons/hi';

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="border-b border-white/10">
      <button
        className="w-full py-6 flex justify-between items-center text-left"
        onClick={onClick}
      >
        <h3 className="text-lg font-medium text-white">{question}</h3>
        <HiChevronUp
          className={`size-6 flex-none text-primary-500 transition-transform duration-200 ${
            isOpen ? '' : 'rotate-180'
          }`}
        />
      </button>
      <div
        className={`overflow-hidden transition-all duration-200 ${
          isOpen ? 'max-h-96 mb-6' : 'max-h-0'
        }`}
      >
        <p className="text-neutral-400 leading-normal">{answer}</p>
      </div>
    </div>
  );
};

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const faqs = [
    {
      question: "What is the usage limit for the templates?",
      answer: "Upon purchasing the templates, you will have full ownership and the freedom to use them an unlimited number of times for various clients."
    },
    {
      question: "Can I redistribute digital items I have bought?",
      answer: "Resale or redistribution of our products is not permitted. By purchasing, you agree to use the products for personal or commercial use only and not for any other purposes."
    },
    {
      question: "Why aren't images and fonts part of the downloaded files?",
      answer: "We are restricted from including the images and fonts used in our mockups as they belong to others. But don't worry, these resources can be obtained for free with the links we have included in the download files."
    },
    {
      question: "Can I get a refund?",
      answer: "Due to the instant access provided for the digital files, we are unable to provide refunds, exchanges, or store credit for any of our products. Please ensure that you have a complete understanding of the product's features and the necessary software and skills to edit the files before making a purchase. If you have any questions, don't hesitate to reach out to us before buying so that we can address your concerns."
    }
  ];

  return (
    <div className="w-full max-w-3xl">
      <div className="space-y-px">
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            onClick={() => setOpenIndex(openIndex === index ? -1 : index)}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQ; 