import React from "react";

const Footer = () => {
  return (
    <div className="w-full px-5 py-6 bg-black border-t border-white/10">
      <div className="flex content-between justify-between w-full max-w-screen-xl mx-auto">
        <p className="text-sm text-neutral-400 hover:text-neutral-300 transition-colors">
          © 2024 Modular. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
