import React, { useEffect, useRef } from "react";
import video from "../images/hero.mp4";

const Hero = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Set video attributes for better performance
      videoRef.current.setAttribute('preload', 'auto');
      videoRef.current.setAttribute('playsinline', '');
      
      // Add loading metadata event
      videoRef.current.addEventListener('loadedmetadata', () => {
        videoRef.current.play();
      });
    }
  }, []);

  return (
    <div className="relative w-full px-8 py-24 flex justify-center items-center h-[500px] md:h-[800px] md:py-32 bg-black">
      {/* Blue blur effect */}
      {/* <div className="absolute inset-0">
        <div className="absolute inset-0 bg-primary-700/30 blur-[300px] transform -rotate-12 -top-44 -left-96 -translate-y-32"></div>
      </div> */}
      
      {/* Content */}
      <div className="relative max-w-3xl mx-auto text-center z-20">
        <p className="font-medium uppercase tracking-widest bg-gradient-to-r from-primary-200 via-primary-500 to-primary-300 text-transparent bg-clip-text text-sm md:text-base mb-2 lg:mb-4">
          Digital Innovation Studio
        </p>
        <h1 className="text-4xl sm:text-5xl sm:leading-tight md:text-6xl max-w-xl md:max-w-3xl mx-auto font-bold md:leading-tight leading-tight bg-gradient-to-r from-neutral-400 via-white to-neutral-400 text-transparent bg-clip-text">
          Crafting Digital Experiences That Inspire
        </h1>
      </div>
      <div className="absolute inset-0 z-10">
        {/* Gradient overlay - fades to black at bottom */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/30 via-black/30 to-black"></div>
      </div>
      <video 
        ref={videoRef}
        src={video} 
        autoPlay 
        muted 
        loop 
        playsInline
        className="absolute inset-0 object-cover w-full h-full z-0" 
      />
    </div>
  );
};

export default Hero;
