import React from 'react';
import Button from './Button';
import stridezApp from '../images/phone_01.webp';

const StridezFeatures = [
  "Personalized workout & meal plans",
  "Perfect-form guidance",
  "Magical nutrition tracking",
  "Beautiful progress analytics"
];

const StridezShowcase = () => {
  return (
    <div className="mt-12 relative">
      {/* Decorative gradient background */}
      <div className="absolute inset-0 bg-gradient-to-br from-primary-500/10 to-transparent rounded-3xl" />
      
      <div className="relative grid items-center grid-cols-1 gap-12 p-8 md:p-12 md:gap-16 md:grid-cols-2">
        <div className="flex flex-col gap-8">
          {/* Header */}
          <div className="flex flex-col gap-4">
            <span className="px-3 py-1 text-sm inline-flex w-fit text-primary-400 bg-primary-500/10 rounded-full">
              Transform Your Training
            </span>
            <h3 className="text-3xl font-bold text-white font-heading md:text-4xl">
              Your Smart Fitness Companion
            </h3>
            <p className="text-lg md:text-xl leading-normal md:leading-normal text-neutral-400">
              Experience a new way to train with Stridez. Get personalized workouts, 
              perfect-form guidance, and achieve your fitness goals with intelligent precision.
            </p>
          </div>

          {/* Features */}
          <ul className="space-y-3">
            {StridezFeatures.map((feature, index) => (
              <li key={index} className="flex items-center text-neutral-400">
                <svg
                  className="w-5 h-5 mr-2 text-primary-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                {feature}
              </li>
            ))}
          </ul>

          {/* CTA */}
          <div className="mt-4">
            <a 
              href="https://stridez.io/" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <Button variant="primary">
                Get Started with Stridez
              </Button>
            </a>
          </div>
        </div>

        {/* App Image */}
        <div className="relative flex justify-center">
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-3xl -z-10" />
          <img
            src={stridezApp}
            alt="Stridez App"
            className="relative h-[500px] w-auto drop-shadow-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default StridezShowcase; 