import React, { useRef, useState } from "react";
import Button from "./Button";
import TextInput from "./TextInput";
import { XANO_BASE } from "../config";

export default function ContactUs() {
  const form = useRef();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async function sendEmail(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData(form.current);
      let formObject = {};
      formData.forEach((value, key) => {
        formObject[key] = value;
      });
      let content = "";
      for (const key in formObject) {
        content += `${capitalizeFirstLetter(key)}: ${formObject[key]}\n`;
      }
      const response = await fetch(`${XANO_BASE}/send_email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content }),
      });
      if (!response.ok) {
        throw new Error("Failed to send email.");
      }
      form.current.reset();
      setError("");
    } catch (error) {
      setError("Failed to send email. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      className="relative flex flex-col gap-6 p-8 bg-black/40 backdrop-blur-sm rounded-xl border border-white/10"
    >
      <TextInput
        label="Name"
        name="name"
        placeholder="John Doe"
        className="bg-black/50 border-white/10 text-white placeholder:text-neutral-500 focus:border-primary-500"
        labelClassName="text-neutral-300"
      />
      <TextInput
        label="Email"
        name="email"
        placeholder="john@email.com"
        className="bg-black/50 border-white/10 text-white placeholder:text-neutral-500 focus:border-primary-500"
        labelClassName="text-neutral-300"
      />
      <div className="flex flex-col">
        <label htmlFor="message" className="mb-1 font-medium text-neutral-300">
          Message
        </label>
        <textarea
          id="message"
          name="message"
          placeholder="How can we help you?"
          className="bg-black/50 border-white/10 text-white placeholder:text-neutral-500 focus:border-primary-500 active:border-primary-500 focus:ring-1 focus-visible:border-primary-500 focus:ring-primary-500 rounded-lg form_input transition-colors"
          style={{ minHeight: "8rem", paddingTop: ".75rem" }}
        />
      </div>
      <div className="w-full">
        <Button
          type="submit"
          className="w-full bg-primary-600 hover:bg-primary-700 text-white border-none shadow-lg shadow-primary-600/20 transition-all hover:shadow-primary-600/40"
          disabled={isLoading}
          isLoading={isLoading}
        >
          Send Email
        </Button>
        {error && <div className="mt-1 text-sm text-red-400">{error}</div>}
      </div>
    </form>
  );
}
