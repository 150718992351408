import React from "react";
import Button from "../components/Button";

const Custom404 = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-4 text-center">
      <div className="flex flex-col max-w-sm gap-1">
        <h1 className="text-5xl font-bold leading-tight text-white">404 Not Found</h1>
        <p className="text-lg mt-2 leading-normal max-w-xs mx-auto text-neutral-400">
          The page you are looking for could not be found
        </p>
      </div>
      <a href="/" className="mt-2">
        <Button>Go back to the homepage</Button>
      </a>
    </div>
  );
};

export default Custom404;
