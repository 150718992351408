import React from "react";
import MainShell from "../components/MainShell";
import SectionHeader from "../components/SectionHeader";
import ProductCard from "../components/ProductCard";
import { useProducts } from "../hooks/useProducts";

const Marketplace = () => {
  const { data: products = [] } = useProducts();

  return (
    <MainShell className="min-h-screen bg-black relative py-16 md:py-24">
      <div className="absolute inset-0 pointer-events-none overflow-visible">
        <div className="absolute -top-44 -left-44 w-[600px] h-[600px] bg-primary-600/20 rounded-full blur-[100px]" />
        <div className="absolute -bottom-44 -right-44 w-[600px] h-[600px] bg-primary-600/15 rounded-full blur-[100px]" />
      </div>

      <div className="relative">
        <SectionHeader
          heading="Digital Products Marketplace"
          subheading="Discover premium design resources and templates"
          className="text-white"
          subheadingClassName="text-neutral-400"
        />
        <div className="grid grid-cols-1 gap-8 mt-16 md:grid-cols-3">
          {products.map((product, index) => (
            <ProductCard key={index} {...product} />
          ))}
        </div>
      </div>
    </MainShell>
  );
};

export default Marketplace;
