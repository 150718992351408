import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { useImagePreloader } from "../hooks/useImagePreloader";

const ProductCard = ({ link, title, price, description, images, category, is_popular, sale_price }) => {
  console.log('ProductCard props:', { is_popular, sale_price });
  const navigate = useNavigate();
  const [imageIndex] = useState(0);
  const isImageLoaded = useImagePreloader(images[imageIndex]?.url);
  
  return (
    <div 
      onClick={() => navigate(`/product/${link}`)}
      className="group relative bg-gradient-to-br from-white/[0.07] to-transparent backdrop-blur-sm border border-white/10 rounded-xl overflow-hidden 
      hover:border-primary-500/50 transition-all duration-300 hover:shadow-2xl hover:shadow-primary-500/20 cursor-pointer 
      before:absolute before:inset-0 before:bg-primary-500/0 before:transition-colors hover:before:bg-primary-500/[0.02] 
      before:rounded-xl"
    >
      {Boolean(is_popular) && is_popular !== '0' && (
        <div className="absolute top-4 right-4 px-3 py-1 bg-primary-500/10 text-primary-500 text-sm font-medium rounded-full z-10">
          Popular
        </div>
      )}
      {Boolean(sale_price) && sale_price !== '0' && Number(sale_price) > 0 && (
        <div className="absolute top-4 left-4 px-3 py-1 bg-red-600/10 text-red-500 text-sm font-medium rounded-full z-10">
          Sale
        </div>
      )}
      <div className="relative aspect-square">
        {!isImageLoaded && (
          <div className="absolute inset-0 bg-black/50 animate-pulse" />
        )}
        <img 
          src={images[imageIndex]?.url}
          alt={title} 
          className={`w-full h-full object-cover relative opacity-90 hover:opacity-100 transition-opacity ${
            isImageLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          loading="lazy"
        />
      </div>
      <div className="p-6">
        <span className="px-3 py-1 text-sm text-primary-400 bg-primary-500/10 rounded-full">
          {category}
        </span>
        <h3 className="mt-4 text-xl font-heading text-white">{title}</h3>
        <p className="mt-2 text-neutral-400 line-clamp-2">{description}</p>
        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="text-2xl font-bold text-white">${price}</span>
            {Boolean(sale_price) && sale_price !== '0' && Number(sale_price) > 0 && (
              <span className="text-lg text-neutral-400 line-through">
                ${sale_price}
              </span>
            )}
          </div>
          <Button variant="primary" className="bg-primary-500 hover:bg-primary-600 text-white">
            Purchase Now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard; 