import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainShell from "../components/MainShell";
import Button from "../components/Button";
import ProductCard from "../components/ProductCard";
import { useProducts } from "../hooks/useProducts";
import SectionHeader from "../components/SectionHeader";
import { HiOutlineClock } from "react-icons/hi";
import { HiCheck } from "react-icons/hi";
import { AiFillStar } from "react-icons/ai";
import { BiLock } from "react-icons/bi";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { LuMousePointerClick } from "react-icons/lu";
import FAQ from "../components/FAQ";

const ProductDetails = () => {
  const { link } = useParams();
  const navigate = useNavigate();
  const { data: products = [] } = useProducts();
  const [selectedImage, setSelectedImage] = useState(0);
  const [timeLeft, setTimeLeft] = useState(24 * 60 * 60); // 24 hours in seconds
  const [selectedTab, setSelectedTab] = useState("features");
  const [isImageZoomed, setIsImageZoomed] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}h ${minutes}m ${secs}s`;
  };

  // Find the current product
  const product = products.find((p) => p.link === link);

  // Get related products (excluding current product)
  const relatedProducts = products.filter((p) => p.link !== link);

  if (!product) {
    return navigate("/marketplace");
  }

  return (
    <MainShell className="min-h-screen bg-black relative py-16 md:py-24">
      <div className="absolute inset-0 pointer-events-none overflow-visible">
        <div className="absolute -top-44 -left-44 w-[600px] h-[600px] bg-primary-600/20 rounded-full blur-[100px]" />
        <div className="absolute -bottom-44 -right-44 w-[600px] h-[600px] bg-primary-600/15 rounded-full blur-[100px]" />
      </div>

      <div className="relative container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Image Gallery */}
          <div className="space-y-4">
            <div
              className="aspect-square rounded-xl overflow-hidden border border-white/10 relative"
              onMouseEnter={() => setIsImageZoomed(true)}
              onMouseLeave={() => setIsImageZoomed(false)}
            >
              <img
                src={product?.images[selectedImage]?.url}
                alt={product?.title}
                className={`w-full h-full object-cover transition-transform duration-500 ${
                  isImageZoomed ? "scale-105" : "scale-100"
                }`}
              />
            </div>
            <div className="grid grid-cols-5 gap-4">
              {product?.images?.map((image, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedImage(index)}
                  className={`aspect-square rounded-lg overflow-hidden border ${
                    selectedImage === index
                      ? "border-primary-500"
                      : "border-white/10"
                  }`}
                >
                  <img
                    src={image?.url}
                    alt={`${product.title} ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
          </div>

          {/* Product Info */}
          <div className="space-y-6">
            <div>
              <span className="px-3 py-1 text-sm text-primary-400 bg-primary-500/10 rounded-full">
                {product?.category}
              </span>
              {product?.badges && (
                <div className="flex flex-wrap gap-3 mt-4">
                  {["⚡ Best Seller", "🔥 Hot Deal", "⭐ Staff Pick"].map(
                    (badge) => (
                      <span
                        key={badge}
                        className="px-3 py-1 text-sm bg-white/5 rounded-full text-white/80 animate-fade-in"
                      >
                        {badge}
                      </span>
                    )
                  )}
                </div>
              )}
              <h1 className="mt-4 text-4xl font-heading text-white">
                {product?.title}
              </h1>

              {/* Add sale timer */}
              {Boolean(product?.sale_price) &&
                Number(product?.sale_price) > 0 && (
                  <div className="mt-4 space-y-2">
                    <div className="inline-flex items-center gap-2 px-4 py-2 bg-red-500/10 rounded-lg">
                      <HiOutlineClock className="w-5 h-5 text-red-500 animate-pulse" />
                      <span className="text-red-500 font-medium">
                        Special offer ends in: {formatTime(timeLeft)}
                      </span>
                    </div>
                    <div className="w-full h-1 bg-white/10 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-red-500 transition-all duration-1000"
                        style={{
                          width: `${(timeLeft / (24 * 60 * 60)) * 100}%`,
                        }}
                      />
                    </div>
                  </div>
                )}

              <p className="text-neutral-400 mt-4">{product?.description}</p>

              {/* Tabs */}
              <div className="mt-4 border-b border-white/10">
                <div className="flex gap-8">
                  {["features", "specifications"].map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setSelectedTab(tab)}
                      className={`pb-4 text-lg font-medium capitalize ${
                        selectedTab === tab
                          ? "text-primary-500 border-b-2 border-primary-500"
                          : "text-neutral-400 hover:text-white"
                      }`}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              </div>

              {/* Tab Content */}
              <div className="mt-6">
                {selectedTab === "features" && (
                  <ul className="space-y-2">
                    {product?.features?.map((feature, index) => (
                      <li
                        key={index}
                        className="flex items-center text-neutral-400"
                      >
                        <HiCheck className="size-5 text-primary-500 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                )}

                {selectedTab === "specifications" && (
                  <div className="space-y-3">
                    {product?.specifications?.map((spec, index) => (
                      <div key={index} className="flex items-start gap-2">
                        <div className="w-32 flex-none text-neutral-400">
                          {spec.label}:
                        </div>
                        <div className="text-white">{spec.value}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Add trust badges */}
            <div className="grid grid-cols-3 gap-4 py-6 border-y border-white/10">
              {[
                { icon: BiLock, text: "Secure Payment" },
                { icon: HiOutlineLightningBolt, text: "Instant Access" },
                { icon: LuMousePointerClick, text: "Easy to Edit" },
              ].map((badge, index) => (
                <div key={index} className="text-center">
                  <badge.icon className="w-8 h-8 mx-auto mb-2 text-primary-500" />
                  <div className="text-sm text-neutral-400">{badge.text}</div>
                </div>
              ))}
            </div>

            {/* Purchase section */}
            <div className="flex items-center justify-between">
              <div>
                <span className="text-3xl font-bold text-white">
                  ${product?.price}
                </span>
                {product?.sale_price && (
                  <span className="text-xl ml-2 font-medium text-neutral-400 line-through">
                    ${product?.sale_price}
                  </span>
                )}
                <p className="text-sm text-neutral-400 mt-1">
                  One-time payment
                </p>
              </div>
              <Button
                variant="primary"
                onClick={() => window.open(product?.gumroad_link, "_blank")}
                className="bg-primary-500 hover:bg-primary-600 text-white"
              >
                Purchase Now
              </Button>
            </div>
            <div>
              <div className="pb-6">
                <h3 className="text-white text-2xl font-medium mb-2">
                  Important Notes
                </h3>
                <ul className="space-y-2 text-neutral-400">
                  <li>• This is a digital product, not a physical product.</li>
                  <li>• Mockups are not included in this file.</li>
                  <li>• Compatible with Mac & Windows.</li>
                </ul>
              </div>

              {/* Copyright notice */}
              <div className="pt-6 border-t border-white/10">
                <h3 className="text-white text-2xl font-medium mb-3">
                  Copyright
                </h3>
                <p className="text-neutral-400 leading-normal">
                  It is strictly prohibited to resell or sub-license this
                  digital product, including any modifications made to it that
                  would compete directly with the original product. This
                  document serves as a template only, and it is up to you to
                  customize, edit, and add to it as needed to present and
                  deliver your own design work to your client. Our final work
                  cannot be resold, reproduced, or represented as your own
                  original design.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-24 relative">
        <SectionHeader
          heading="Related Products"
          subheading="You might also like these templates"
          className="text-white"
          subheadingClassName="text-neutral-400"
        />
        <div className="grid grid-cols-1 gap-8 mt-16 md:grid-cols-3">
          {relatedProducts?.map((product, index) => (
            <ProductCard key={index} {...product} />
          ))}
        </div>
      </div>

      {/* Add FAQ section */}
      <div className="mt-24 relative">
        <SectionHeader
          heading="Frequently Asked Questions"
          subheading="Find answers to common questions about our products"
          className="text-white"
          subheadingClassName="text-neutral-400"
        />
        <div className="mt-16">
          <FAQ />
        </div>
      </div>
    </MainShell>
  );
};

export default ProductDetails;
